import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/landing/login/login.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(public router: Router,private modalService: NgbModal) { }

  currentUser:any

  ngOnInit(): void {
    // localStorage.removeItem('UC_currentUser');
    // localStorage.removeItem('token');
    this.currentUser = JSON.parse(localStorage.getItem('UC_currentUser'));
  }
  onLogo(){
    this.router.navigateByUrl('')
  }

  OnLogin(){
     this.currentUser = JSON.parse(localStorage.getItem('UC_currentUser'));
    this.router.navigateByUrl('')
  }

  login(){
    var comp = this.modalService.open(LoginComponent, { size: 'md', centered: true });
    comp.componentInstance.callBack = this
  }

}
