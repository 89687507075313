
<div class="p-sec-body" *ngIf="type == 1 else SignUp">
    <div class="p-form-sec">
        <p class="p-form-sec-header" style="margin-bottom: 10px;">{{heading}}</p>
        <p class="p-form-sec-title" style="margin-bottom: 5px;">{{title}}
            <p class="p-form-sec-head-text">{{subTitle}}
        </p>
        <div class="alert alert-danger mb-xl-0 mt-3" role="alert" style="margin: 0 25px 0 25px;" *ngIf="DisplayMessage">
            <strong> {{DisplayMessage}} </strong>
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="onLogin()">

        <div class="p-form card-body">

            
            <div class="p-field">
                <label>Email/Mobile No.</label>
                <input type="email" class="p-input-form" id="email" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                placeholder="Enter email">
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                    <div *ngIf="f['email'].errors['email']">Email must be a valid email address
                    </div>
                </div>
                
            </div>
            <div class="p-field mt-2">
                <label>Password</label>
               
                <input [type]="fieldTextType ? 'text' : 'password'" class="p-input-form pe-5" placeholder="Enter password" id="password-input" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                }" (click)="toggleFieldTextType()"></i></button>
                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                  <span *ngIf="f['password'].errors['required']">Password is required</span>
                </div>

                <!-- <input type="password" [(ngModel)]="password" class="p-input-form"> -->
            </div>
           
            <button class="footer-btn price-cont-btn " style="background-color: #f37021;margin-top: 20px;width: 50%;" >Login</button>

            <div class="mt-1 text-center">
                <p class="mb-0" style="font-size: 12px;">Don't have an account ? <a (click)="type = 2" class="fw-semibold text-primary" style="font-size: 15px;color: #f37021 !important;"> Signup </a> </p>
            </div>
           
        </div>
        </form>
    </div>
</div>
<ng-template #SignUp>
    <div class="p-sec-body" *ngIf="type == 2 else OTP">
        <div class="p-form-sec">
            <p class="p-form-sec-header" style="margin-bottom: 10px;">{{heading}}</p>
            <p class="p-form-sec-title" style="margin-bottom: 5px;">{{title}}
                <p class="p-form-sec-head-text">{{subTitle}}
            </p>
            <div class="alert alert-danger mb-xl-0 mt-3" role="alert" style="margin: 0 25px 0 25px;" *ngIf="DisplayMessage">
                <strong> {{DisplayMessage}} </strong>
            </div>
    
            <form [formGroup]="signupForm" (ngSubmit)="onSignUp()">
    
            <div class="p-form card-body">
    
                
                <div class="p-field">
                    <label>Name</label>
                    <input type="text" class="p-input-form" id="Name" formControlName="Name"
                    [ngClass]="{ 'is-invalid': submitted && s['Name'].errors }"
                    placeholder="Enter Name">
                    <div *ngIf="submitted && s['Name'].errors" class="invalid-feedback">
                        <div *ngIf="s['Name'].errors['required']">Name is required</div>
                    </div>
                    
                </div>
                <div class="p-field">
                    <label>Email ID</label>
                    <input type="email" class="p-input-form" id="Email" formControlName="Email"
                    [ngClass]="{ 'is-invalid': submitted && s['Email'].errors }"
                    placeholder="Enter Email">
                    <div *ngIf="submitted && s['Email'].errors" class="invalid-feedback">
                        <div *ngIf="s['Email'].errors['required']">Email is required</div>
                        <div *ngIf="s['Email'].errors['Email']">Email must be a valid email address
                        </div>
                    </div>
                    
                </div>
                <div class="p-field">
                    <label>Mobile No</label>
                    <input type="tel" class="p-input-form" id="Mobile" formControlName="Mobile"
                    [ngClass]="{ 'is-invalid': submitted && s['Mobile'].errors }"
                    placeholder="Enter Mobile">
                    <div *ngIf="submitted && s['Mobile'].errors" class="invalid-feedback">
                        <div *ngIf="s['Mobile'].errors['required']">Mobile is required</div>
                    </div>
                    
                </div>
                <div class="p-field mt-2">
                    <label>Password</label>
                   
                    <input [type]="fieldTextType ? 'text' : 'password'" class="p-input-form pe-5" placeholder="Enter Password" id="Password-input" formControlName="Password"
                    [ngClass]="{ 'is-invalid': submitted && s['Password'].errors }">
                    <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="Password-addon"><i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                    }" (click)="toggleFieldTextType()"></i></button>
                    <div *ngIf="submitted && s['Password'].errors" class="invalid-feedback">
                      <span *ngIf="s['Password'].errors['required']">Password is required</span>
                    </div>
    
                    <!-- <input type="password" [(ngModel)]="password" class="p-input-form"> -->
                </div>
               
                <button class="footer-btn price-cont-btn " style="background-color: #f37021;margin-top: 20px;width: 50%;" >SignUp</button>
    
                <div class="mt-1 text-center">
                    <p class="mb-0" style="font-size: 12px;">Already have an account ? <a (click)="type = 1" class="fw-semibold text-primary" style="font-size: 15px;color: #f37021 !important;"> Login </a> </p>
                </div>
               
            </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #OTP>
    <div class="p-sec-body" >
        <div class="p-form-sec">
            <p class="p-form-sec-header" style="margin-bottom: 10px;">{{heading}}</p>
            <p class="p-form-sec-title" style="margin-bottom: 5px;">{{title}}
                <p class="p-form-sec-head-text">{{subTitle}}
            </p>
            <div class="alert alert-danger mb-xl-0 mt-3" role="alert" style="margin: 0 25px 0 25px;" *ngIf="DisplayMessage">
                <strong> {{DisplayMessage}} </strong>
            </div>
    
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
    
            <div class="p-form card-body">
    
                
                <div class="p-field">
                    <label>Email/Mobile No.</label>
                    <input type="email" class="p-input-form" id="email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                    placeholder="Enter email">
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors['required']">Email is required</div>
                        <div *ngIf="f['email'].errors['email']">Email must be a valid email address
                        </div>
                    </div>
                    
                </div>
                <div class="p-field mt-2">
                    <label>Password</label>
                   
                    <input [type]="fieldTextType ? 'text' : 'password'" class="p-input-form pe-5" placeholder="Enter password" id="password-input" formControlName="password"
                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                    <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                    }" (click)="toggleFieldTextType()"></i></button>
                    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                      <span *ngIf="f['password'].errors['required']">Password is required</span>
                    </div>
    
                    <!-- <input type="password" [(ngModel)]="password" class="p-input-form"> -->
                </div>
               
                <button class="footer-btn price-cont-btn " style="background-color: #f37021;margin-top: 20px;width: 50%;" >SignUp</button>
    
                <div class="mt-1 text-center">
                    <p class="mb-0" style="font-size: 12px;">Already have an account ? <a href="auth-signup-basic.html" class="fw-semibold text-primary" style="font-size: 15px;color: #f37021 !important;"> Login </a> </p>
                </div>
               
            </div>
            </form>
        </div>
    </div>
</ng-template>