export const GlobalComponent = {
    // Api Calling
    // UNIVERCELL_API_URL : 'https://localhost:47642/api/',
    UNIVERCELL_API_URL : 'https://booking.univercellcanada.com:2053/api/',
    // TRAVEL_API_URL : 'http://ec2-15-207-87-86.ap-south-1.compute.amazonaws.com:2019/api/',
    API_URL : 'https://api-node.themesbrand.website/',
    headerToken : {'Authorization': `Bearer ${localStorage.getItem('token')}`},

    // Auth Api
    AUTH_API:"https://api-node.themesbrand.website/auth/",
    
    // Products Api
    product:'apps/product',
    productDelete:'apps/product/',

    // Orders Api
    order:'apps/order',
    orderId:'apps/order/',

    // Customers Api
    customer:'apps/customer',
    // stripe: 'pk_test_0ggNlQojQvRp30PrHVQUr8s400trI5gAIf',
    stripe: 'pk_live_51OgcovH7JuNjg9QsSsg3XH95Y33AlHSAttXmLFgqiTdFa2rUoqBOkfGE1lbDut836ubGjpTYypWbEifJsIqjcPRz00M0kYAzRf',
}
