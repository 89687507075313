<div class="nav-section">
    <div class="nav">
        <div class="icon">
            <img class="icon-image" style="cursor: pointer;" (click)="onLogo()" src="assets\images\logo.png">
        </div>
        <div class="nav-main">
            <!-- <div class="search-bar">
                <button class="location-button">Location <i class="bi bi-chevron-down"></i></button>
                <input class="search-field" placeholder="Type your phone brand, model and repair">
                <button class="search-button"><i class="bi bi-search"></i></button>
            </div> -->
            <div class="action-buttons">
                <!-- <a class="action-button">Register as Partner</a> -->
                <!-- <a class="action-button" (click)="login()" *ngIf="currentUser == null">Log in/sign Up</a> -->
            </div>
        </div>
    </div>
    <div class="mobile-nav">
        <div class="sidemenu-button">
            <a class="side-menu-button" href="side-menu-2.html"><i
                    class=" side-menu-button bi bi-filter-left"></i></a>
        </div>
        <div class="icon">
            <img class="icon-image" (click)="onLogo()" src="assets\images\logo.png">
        </div>
        <a class="search-button-mobile"><i class=" search-button-mobile bi bi-search"></i></a>
    </div>
</div>