import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  /**
   *
   */
  constructor(private authenticationService: AuthenticationService,private formBuilder: UntypedFormBuilder,public router: Router,private modalService: NgbModal) {
    
  }

  loginForm!: UntypedFormGroup;
  signupForm!: UntypedFormGroup;

  heading = 'Sign In'
  title = 'Find your sparkle!'
  subTitle = 'You\'re just one step away from signing up'

  DisplayMessage:any

  username = ''
  password = ''

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
    this.loginForm = this.formBuilder.group({
      email: ['admin@univercell.com', [Validators.required, Validators.email]],
      password: ['Pass@123', [Validators.required]],
    });
    this.signupForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      CountryCode: [''],
      Email: ['', [Validators.required, Validators.email]],
      Mobile: ['', [Validators.required]],
      Password: ['', [Validators.required]],
    });

  }

  type = 1  //  1 - Login, 2 - Signup, 3 - OTP

  submitted = false

  get f() { return this.loginForm.controls; }
  get s() { return this.signupForm.controls; }

  onLogin(){
    this.DisplayMessage = null

    this.submitted = true;
    if (!this.loginForm.valid) {
      return
    }
    document.getElementById('elmLoader')?.classList.remove('d-none')   
    this.authenticationService.login(this.f['email'].value, this.f['password'].value).subscribe((data:any) => {   
      document.getElementById('elmLoader')?.classList.add('d-none')   
      if(data.IsSuccess == true && data.Data.length != 0){

        this.getToken(data.Data[0]);
      
      } else {
        this.DisplayMessage = data.Message
      }
    });
  }

  onSignUp(){
    this.DisplayMessage = null

    this.submitted = true;
    if (!this.signupForm.valid) {
      return
    }
    document.getElementById('elmLoader')?.classList.remove('d-none')   
    this.authenticationService.signup(this.signupForm.value).subscribe((data:any) => {   
      document.getElementById('elmLoader')?.classList.add('d-none')   
      
      if(data.IsSuccess == true && data.Data.length != 0){

        this.getToken(data.Data);
      
      } else {
        this.DisplayMessage = data.Message
      }
    });
  }

  callBack:any

  getToken(model){    
    this.DisplayMessage = null

    if (this.type == 1) {
      model.Password = this.f['password'].value      
    }else if (this.type == 2) {
      model.Password = this.s['Password'].value      
    }
    document.getElementById('elmLoader')?.classList.remove('d-none')   

    this.authenticationService.token(model).subscribe((data:any) => {   
      document.getElementById('elmLoader')?.classList.add('d-none')   
      if(data.IsSuccess == true){

        localStorage.setItem('UC_currentUser', JSON.stringify(model));
        localStorage.setItem('token', data.Data.Token);
       
        this.modalService.dismissAll()
        if (this.callBack == null) {
          this.router.navigate(['/']);
        }else{
          this.callBack.OnLogin()
        }

      } else {
        this.DisplayMessage = data.Message
      }
    });
  }
  fieldTextType!: boolean;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
