<div class="footer-section">
    <div class="footer">
        <div class="footer-sec-1">
            <div class="icon">
                <img class="icon-image" src="assets\images\logo.png">
            </div>
            <div class="footer-sub-sec">
                <div class="footer-sub-sec-1">
                    <p class="footer-sec-1-tile">COMPANY</p>
                    <p class="footer-sec-1-link">About Us</p>
                    <p class="footer-sec-1-link">Blogs</p>
                    <p class="footer-sec-1-link">Repair Now Pay Later</p>
                </div>
                <div class="footer-sub-sec-1">
                    <p class="footer-sec-1-tile">PARTNERS</p>
                    <p class="footer-sec-1-link">Regular</p>
                    <p class="footer-sec-1-link">Partner FAQ</p>
                    <p class="footer-sec-1-link">Registered Partners</p>
                </div>
                <div class="footer-sub-sec-1">
                    <p class="footer-sec-1-tile">FOR YOU</p>
                    <p class="footer-sec-1-link">Privacy</p>
                    <p class="footer-sec-1-link">FAQ</p>
                    <p class="footer-sec-1-link">Contact</p>
                </div>
            </div>
        </div>
        <div class="footer-sec-2">
            <p class="footer-sec-2-tagline">Subscribe to win<br> weekly rewards</p>
            <div>
                <input class="email-address-input" placeholder="Email Address">
                <button class="sub-btn">SUBSCRIBE</button>
            </div>
            <div class="icons">
                <i class="bi bi-facebook"></i>
                <i class="bi bi-instagram"></i>
                <i class="bi bi-youtube"></i>
                <i class="bi bi-twitter"></i>
            </div>
        </div>
    </div>
</div>